import axios from "axios";

const http = axios.create({
  baseURL: "https://sistema.zettabrasil.com.br/zetta-consultas/api/cep",
  headers: { "Access-Control-Allow-Origin": "*" },
});

export default async cep => {
  const response = await http.get(cep);

  if (response.status !== 200) {
    throw { message: "Nenhuma informação recuperada" };
  }

  return response.data;
};